import axios from "axios";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import gsap from "gsap";
import parse from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import { FaInfoCircle } from "react-icons/fa";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Logos from "../components/logos";
import PortfolioSection from "../components/portfolio-section";
import PricingCard from "../components/pricing-card";
import useOnScreen from "../components/use-on-screen";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			wpSeoPage(title: { eq: "Home" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			wpHomePage {
				homePageFields {
					backgroundImage {
						altText
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					bookAMeetingSection {
						title
						description
					}
					growSection {
						title
						smallTitle
						description
						button1 {
							link
							text
						}
					}
					heroSection {
						title
						description
						button2 {
							text
							link
						}
						button1 {
							text
							link
						}
					}
					howRjmCanHelpSection {
						button1 {
							link
							text
						}
						title
						description
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					portfolioSection {
						title
						description
					}
					sustainabilitySection {
						title
						description
						button1 {
							text
							link
						}
					}
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			webDevimg: wpMediaItem(title: { eq: "web-design-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg2: wpMediaItem(title: { eq: "side-image-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			bgImg: wpMediaItem(title: { eq: "home-background-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg1: wpMediaItem(title: { eq: "shopify" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			logoImg3: wpMediaItem(title: { eq: "Gatsby-Logo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg4: wpMediaItem(title: { eq: "Firebase" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg5: wpMediaItem(title: { eq: "Semrush" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg6: wpMediaItem(title: { eq: "ECO_IMAGE_1" }) {
				altText

				mediaDetails {
					height
					width
				}

				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg7: wpMediaItem(title: { eq: "ECO_IMAGE_2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg8: wpMediaItem(title: { eq: "ECO_IMAGE_3" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg9: wpMediaItem(title: { eq: "ECO_IMAGE_4" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "seo-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			gAdsImg: wpMediaItem(title: { eq: "google-ads-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ecommerceImg: wpMediaItem(title: { eq: "e-commerce-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const [trees, setTrees] = useState("");
	const [carbon, setCarbon] = useState("");
	const [months, setMonths] = useState("");
	const [selectedImage, setSelectedImage] = useState(data.webDevimg);

	function monthDiff(dateFrom, dateTo) {
		return (
			dateTo.getMonth() -
			dateFrom.getMonth() +
			12 * (dateTo.getFullYear() - dateFrom.getFullYear())
		);
	}

	useEffect(() => {
		axios
			.get(`https://public.ecologi.com/users/rjmdigital/impact`)
			.then((res) => {
				setTrees(res.data.trees);
				setCarbon(res.data.carbonOffset);
				setMonths(monthDiff(new Date(2021, 10), new Date()));
			});
	}, []);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const ref1 = useRef();
	const onScreen1 = useOnScreen(ref1, "0px");

	const {
		wpHomePage: {
			homePageFields: {
				backgroundImage,
				bookAMeetingSection,
				growSection,
				heroSection,
				howRjmCanHelpSection,
				portfolioSection,
				sustainabilitySection,
			},
		},
	} = data;

	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
				},
			},
		],
	};

	const enterAnimation = (text, img) => {
		if (selectedImage !== img) {
			gsap.to(
				".selected-image",

				{ opacity: 0, duration: 0.5 }
			);
			gsap.to(
				".selected-image",

				{ opacity: 1, duration: 1, delay: 0.6 }
			);
			setTimeout(function () {
				setSelectedImage(img);
			}, 500);
		}

		gsap.to(
			text,

			{ opacity: 1, color: "#FFCA2C", duration: 0.5 }
		);
	};
	const exitAnimation = (text) => {
		gsap.to(
			text,

			{ opacity: 0.69, color: "white", duration: 0.5 }
		);
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>

			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link={heroSection.button1.link}
						button1Var="white"
						button2="white-link-yellow"
						button2Text={heroSection.button2.text}
						button2Link={heroSection.button2.link}
						button2Var="primary"
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				<section className="bg-light-grey pt-5 pt-lg-8">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6} className="text-center ">
								<p className="ssp-bold text-uppercase text-light-blue">
									{growSection.smallTitle}
								</p>
								<div>{parse(growSection.title)}</div>

								<div>{parse(growSection.description)}</div>
								<Button
									className="mt-5 cta-btn fs-5 w-100 w-md-auto py-2 px-4 white-link"
									variant="primary"
									as={Link}
									to={growSection.button1.link}
								>
									{growSection.button1.text}
								</Button>
								<Button
									className="px-4 py-2 mt-5 bg-white border-white py-2  w-100 cta-btn w-md-auto fs-5 blue-link-yellow"
									variant="primary"
									as={Link}
									to="/contact-us"
								>
									Register my interest
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<Logos noTitle />
				{/* <section>
					<TeamSection />
				</section> */}

				<section className="py-5 py-lg-7 bg-light-grey">
					<PortfolioSection
						title={parse(portfolioSection.title)}
						description={portfolioSection.description}
						page="Home"
					/>
				</section>
				{/* <section className="pt-5 pb-md-5 py-lg-7 pb-md-5 bg-light-grey">
					<ReviewSection page="Home" />
				</section> */}
				{/* <section className="vh-100 d-none d-xl-block ">
					<GatsbyImage
						className="vh-100 position-fixed w-100"
						style={{ zIndex: "-9999", top: "0" }}
						image={backgroundImage.gatsbyImage}
						alt=""
					/>
				</section> */}
				<section className="pt-5 pt-lg-7 bg-light-grey">
					<Container>
						<Row className="justify-content-between align-items-lg-start ">
							<Col className=" pt-5 pb-5 pb-lg-9 mb-xl-5" lg={6}>
								<div>{parse(howRjmCanHelpSection.title)}</div>

								<div>{parse(howRjmCanHelpSection.description)}</div>

								<Button
									className=" cta-btn w-100 w-md-auto fs-5 white-link-yellow py-2 px-4 mt-4 "
									variant="primary"
									as={Link}
									to={howRjmCanHelpSection.button1.link}
								>
									{howRjmCanHelpSection.button1.text}
								</Button>
								<Button
									className="px-4 py-2 mt-4 bg-white border-white  w-100 cta-btn w-md-auto fs-5 blue-link-yellow"
									variant="primary"
									as={Link}
									to="/contact-us"
								>
									Register my interest
								</Button>
							</Col>
							<Col className="position-relative pt-5" xl={4} lg={5}>
								{/* <div
									className=" d-none d-lg-block d-xl-none w-100 mb-5 "
									style={{
										borderRadius: "15px",
										overflow: "hidden",

										boxShadow: "0px 3px 70px #00000086",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg1.gatsbyImage
										}
										alt=""
									/>
								</div> */}
								<div
									className="  d-none d-lg-block  w-100 mb-5 "
									style={{
										borderRadius: "15px",
										overflow: "hidden",

										boxShadow: "0px 3px 70px #00000086",
									}}
								>
									<GatsbyImage
										image={howRjmCanHelpSection.image.gatsbyImage}
										alt=""
										className="w-100"
									/>
								</div>
								{/* <div
									className=" d-none d-xl-block w-100  end-0 position-absolute "
									style={{
										borderRadius: "10px",
										overflow: "hidden",
										top: "-1.5rem",
										boxShadow: "0px 3px 90px #00000043",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg1.gatsbyImage
										}
										alt=""
									/>
								</div> */}

								{/* <div
									className=" d-none d-xl-block position-absolute w-100 "
									style={{
										borderRadius: "10px",
										overflow: "hidden",
										left: "-7rem",
										top: "13rem",
										boxShadow: "0px 3px 90px #00000043",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg2.gatsbyImage
										}
										alt=""
									/>
								</div> */}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7" id="pricing">
					{" "}
					<Container className="pricing-heading position-relative">
						<Row>
							<Col>
								<h2 className="pb-5 text-center text-primary display-5">
									Pricing
								</h2>
							</Col>
						</Row>
						<Row className={` justify-content-center `}>
							<PricingCard
								btnColour="secondary"
								title="Initium"
								subText="For businesses and start-ups launching smaller projects, individual campaigns, or landing pages."
								price="$3,995"
								monthly
								setupFee="$135"
								features={[
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Unlimited page website{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Our websites are powered by reusable ACF and
															ReactJS components, so you can create and build as
															many pages as you want, without requiring RJM
															support
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Up to 5 custom pages designed{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Typical pages include: Home, About, Services, Blog
															and Contact
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"Mobile-responsive design",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Fully managed website, including maintenance{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>Excludes domain</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"GatsbyJS frontend",
									"Headless WordPress Content Management System",
									"Unlimited images, audio and file content",
									"Fast and secure web hosting on Netlify",
									"72 hour support response time",
									"SSL certificate",
									"Custom forms using Formspree",
									"Google Tag Manager and Google Analytics",
								]}
							/>
							<PricingCard
								btnColour="dark-blue"
								middle
								title="Proventus"
								subText={
									<span>
										For businesses ready to expand their online presence and
										scale.
									</span>
								}
								price="$6,995"
								monthly
								setupFee="$245"
								features={[
									<span>
										Everything in <strong>Initium</strong>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Up to 10 custom pages designed{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Typical pages include: Home, About, Services,
															Blog, Contact, Case studies, FAQ,
															Portfolio/Customers, Google Ad and local SEO pages{" "}
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"Fully bespoke design tailored to your brand",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Additional 10 custom components designed{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Includes all pre-built RJM components{" "}
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Advanced SEO Implementation{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															We will create the Meta TItle, Meta Description,
															Schema Mark-up and Open Graph content for each
															page
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,

									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Zapier integrations for automated workflows{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Connect form submissions and other data to your
															CRM and other marketing tools
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"48 hour support response time",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Copywriting support{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Complete website copywriting, including 2
															revisions
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
								]}
							/>
							<PricingCard
								btnColour="light-blue"
								title="Optimus"
								subText="For established businesses and enterprises needing an advanced solution."
								price="$11,995"
								monthly
								setupFee="$395"
								features={[
									<span>
										Everything in <strong>Proventus</strong>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Up to 50 custom pages and components{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Includes all RJM pre-built components
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									// <span className="d-flex align-items-center">
									// 	<p className="pb-0 mb-0 d-inline-block ssp-regular">
									// 		Deep custom integrations{" "}
									// 		<OverlayTrigger
									// 			placement="top"
									// 			overlay={
									// 				<Popover id={`popover-positioned-top`}>
									// 					<Popover.Body>
									// 						CRM, API's and other marketing platforms
									// 					</Popover.Body>
									// 				</Popover>
									// 			}
									// 		>
									// 			<FaInfoCircle
									// 				style={{ width: "10px", color: "#40B4E5" }}
									// 				className="ms-2 d-inline-block"
									// 			/>
									// 		</OverlayTrigger>
									// 	</p>
									// </span>,

									"24 SLA support response time",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Full copywriting included{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Complete website copywriting, including 3
															revisions
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
								]}
							/>
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>

				{/* <section
					style={{
						background:
							"transparent linear-gradient(180deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5 py-lg-7"
				>
					<Container>
						<Row className="justify-content-center">
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg1.gatsbyImage}
									alt={data.logoImg1.altText}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} className="d-flex align-items-center" lg={true}>
								<StaticImage
									src="../images/Wordpress-Final.png"
									placeholder="blurred"
									quality={100}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg3.gatsbyImage}
									alt={data.logoImg3.altText}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg4.gatsbyImage}
									alt={data.logoImg4.altText}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg5.gatsbyImage}
									alt={data.logoImg5.altText}
									className="small-max-width"
								/>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section
					style={{ overflow: "hidden" }}
					className="py-5 pb-lg-7 pt-lg-9 position-relative bg-dark-bg "
				>
					<div
						style={{ height: "1rem", backgroundColor: "#27676A", zIndex: 2 }}
						className="w-100 position-absolute bottom-0"
					></div>
					<StaticImage
						className=" start-0 position-absolute"
						style={{
							zIndex: 1,
							width: "100%",
							maxHeight: "100%",
							bottom: "0.5rem",
						}}
						src="../images/Sustainble-background.svg"
						placeholder="blurred"
						quality={100}
					/>
					<Container style={{ zIndex: 3 }} className="position-relative">
						<Row className="justify-content-center">
							<Col lg={8} xl={7} className="text-center pb-5">
								{" "}
								<div>{parse(sustainabilitySection.title)}</div>
								<div>{parse(sustainabilitySection.description)}</div>
								<Button
									className=" cta-btn w-100 w-md-auto fs-5 primary-link-dark py-2 px-4 "
									variant="white"
									as={Link}
									to={sustainabilitySection.button1.link}
								>
									{sustainabilitySection.button1.text}
								</Button>
							</Col>
						</Row>
						<Row>
							<Col>
								<h3 className="display-5 pb-5 text-white">Our partnerships</h3>
							</Col>
						</Row>
						<Row className="gy-5">
							<Col className="text-center" xs={6} md={3}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://ecofriendlyweb.org/client/rjm-digital/"
								>
									<div
										style={{
											borderRadius: "30px",
											overflow: "hidden",
											width: "110px",
											height: "110px",
										}}
										className="bg-white d-flex justify-content-center mx-auto align-items-center"
									>
										<GatsbyImage
											style={{ maxWidth: "90px" }}
											image={data.logoImg6.gatsbyImage}
											alt={data.logoImg6.altText}
										/>
									</div>
								</a>
							</Col>
							<Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={data.logoImg7.gatsbyImage}
										alt={data.logoImg7.altText}
									/>
								</div>
							</Col>
							<Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={data.logoImg8.gatsbyImage}
										alt={data.logoImg8.altText}
									/>
								</div>
							</Col>
							<Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={data.logoImg9.gatsbyImage}
										alt={data.logoImg9.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(180deg, #27676A 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5  py-lg-7 pb-lg-9"
				>
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-5 text-white">Our impact</h2>
							</Col>
						</Row>
						<Row className="d-none d-md-flex" ref={ref1}>
							<Col className="text-center" xs={6} md={4}>
								<div
									style={{ height: "190px" }}
									className="d-flex align-items-center justify-content-center"
								>
									<StaticImage
										src="../images/ECO 1 - SVG.svg"
										placeholder="blurred"
										style={{ maxWidth: "100px" }}
										quality={100}
										className="mb-3 d-none d-md-block d-lg-none"
									/>
									<StaticImage
										src="../images/ECO 1 - SVG.svg"
										placeholder="blurred"
										style={{ maxWidth: "200px" }}
										quality={100}
										className="mb-4 d-md-none d-lg-block"
									/>
								</div>
								<h3 className="text-white fs-1">
									{onScreen1 && <CountUp end={months} />} months
								</h3>
								<p className="text-white">Climate impact</p>
							</Col>
							<Col className="text-center pb-5 pb-lg-0" xs={6} md={4}>
								<div
									style={{ height: "190px" }}
									className="d-flex align-items-center justify-content-center"
								>
									<StaticImage
										src="../images/ECO 2 - svg.svg"
										placeholder="blurred"
										style={{ maxWidth: "100px" }}
										quality={100}
										className="mb-3 d-none d-md-block d-lg-none"
									/>
									<StaticImage
										src="../images/ECO 2 - svg.svg"
										placeholder="blurred"
										style={{ maxWidth: "200px" }}
										quality={100}
										className="mb-4 d-md-none d-lg-block"
									/>
								</div>
								<h3 className="text-white fs-1">
									{onScreen1 && <CountUp decimals={2} end={carbon} />}t
								</h3>
								<p className="text-white">Carbon reduction</p>
							</Col>
							<Col className="text-center" md={4}>
								<div
									style={{ height: "190px" }}
									className="d-flex align-items-center justify-content-center"
								>
									<StaticImage
										src="../images/ECO 3 - svg.svg"
										placeholder="blurred"
										style={{ maxWidth: "100px" }}
										quality={100}
										className="mb-3 d-none d-md-block d-lg-none"
									/>
									<StaticImage
										src="../images/ECO 3 - svg.svg"
										placeholder="blurred"
										style={{ maxWidth: "200px" }}
										quality={100}
										className="mb-4 d-md-none d-lg-block"
									/>
								</div>
								<h3 className="text-white fs-1">
									{onScreen1 && <CountUp end={trees} />}
								</h3>
								<p className="text-white">Trees in our forest</p>
							</Col>
						</Row>
						<Row className="d-md-none">
							<Col>
								<Carousel interval={3000}>
									<Carousel.Item>
										<div className="pb-5">
											<div className="text-center" style={{ height: "200px" }}>
												<StaticImage
													src="../images/ECO 1 - SVG.svg"
													placeholder="blurred"
													style={{ maxWidth: "200px" }}
													quality={100}
													className="mb-4"
												/>
											</div>
											<div className="text-center">
												<h3 className="text-white fs-1">{months} months</h3>
												<p className="text-white">Climate impact</p>
											</div>
										</div>
									</Carousel.Item>
									<Carousel.Item>
										<div className="pb-5 text-center">
											<div className="text-center" style={{ height: "200px" }}>
												<StaticImage
													src="../images/ECO 2 - svg.svg"
													placeholder="blurred"
													style={{ maxWidth: "200px" }}
													quality={100}
													className="mb-4"
												/>
											</div>
											<h3 className="text-white fs-1">{carbon}t</h3>
											<p className="text-white">Carbon reduction</p>
										</div>
									</Carousel.Item>
									<Carousel.Item>
										<div className="pb-5 text-center">
											<div className="text-center" style={{ height: "200px" }}>
												<StaticImage
													src="../images/ECO 3 - svg.svg"
													placeholder="blurred"
													style={{ maxWidth: "200px" }}
													quality={100}
													className="mb-4"
												/>
											</div>
											<h3 className="text-white fs-1">{trees}</h3>
											<p className="text-white">Trees in our forest</p>
										</div>
									</Carousel.Item>
								</Carousel>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default IndexPage;
